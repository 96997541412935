.date-time-selector {
    svg {
        font-size: 1.15em;
        margin-right: 3px;
    }
    .tooltip {
        background-color: red;
        display: flex;
        align-items: center;
        justify-items: center;
    }
}
@media screen and (max-width: 1200px) {
    .date-time-selector {
        font-size: 1em;
        padding: 8px;
        color:#ddd;
        line-height: 1.5;
        svg {
            margin-right: 0;
        }
        span {
            display: none;
        }
    }
}