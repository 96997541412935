.status-bar {
    display: flex;
    justify-content: space-between;
    padding: 0px 7px;
}
.status-options {
    display: flex;
    align-items: center;
}
.status-selectors {
    display: flex;
    align-items: center;
    .selector {
        margin-left: 10px;
        .label {
            flex: 1;
            color: #bfbfbf;
            white-space: nowrap;;
            text-transform: uppercase;
            border-radius: 3px;
            font-size: 12px;
        }
    }
    & div {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 565px) {
        display: none;
    }
}
.date-selector {
    input {
        color: orange;
        background: #121212;
        border: none;
        margin: 3px;
        padding: 3px 6px;
        font-size: 13px;
        border-radius: 3px;
        &.limit {
            width: 50px;
        }
 
        &.date-time-range {
            width: 120px;
            color: orange;
            background: #121212;
        }
    }
}

.logo {
margin-left: 5px;
}

.api-url-selector {
    margin-left: 20px;
    display: flex;
    align-items: center;
    transition: 0.2s all;
    input {
        color: orange;
        background: #121212;
        border: none;
        margin: 3px;
        padding: 3px 6px;
        font-size: 13px;
        border-radius: 3px;
     
    }
    button {
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 18px;
        border: none;
        margin: 3px;
        padding: 5px 8px;
        border-radius: 3px;
        background: #7b7b7b3b;
        color: #d1d1d1;
        font-size: 0.9em;
        cursor: pointer;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: 0.2s all;
        &:hover {
            background: #9e9e9e3b;
        }
    }
}

.logo-section {
    display: flex;
    align-items: center;
    flex: 1;
}

.url-copy {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 18px;
    border: none;
    margin: 3px;
    padding: 5px 8px;
    border-radius: 3px;
    background: #7b7b7b3b;
    // color: #d1d1d1;
    color: orange;
    font-size: 0.9em;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: 0.2s all;
    span {
        margin-left: 4px;
        text-transform: uppercase;
        font-size: 12px;
        color: #d1d1d1;
    }
    &:hover {
        background: #9e9e9e3b;
    }
    &:disabled {
        color: #7b7b7b;
        cursor: not-allowed;
    }
}
@media screen and (max-width: 950px) {
    .url-copy {
        span {
            display: none;
        }
    }
}
.copied-warning {
    transition: 0.2s all;
    color: orange;
    font-size: 0.8em;
    text-transform: uppercase;
    margin-right: 10px;
}
.submit-checkbox {
    color: red;
    background-color: green;
}
