* {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #61616136;
    }
}
body {
    background: $grey-dark;
    font-family: Helvetica, Arial, sans-serif;
    margin: 0 auto;
    padding: 0 auto;
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
      }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #61616136;
    }
}
html {
    background: $grey-dark;
}
