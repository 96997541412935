
$grey-lighter: #919191;
$grey-light: #61616136;
$grey-medium: #424242;
$grey-dark: #212121;
$dark-quoise : #11abab;
$dark-quoise-hover : #14b8b8;
$black-quoise: #121212;
$dirty-white: hsl(0, 0%, 91%);

$loglevel-critical: purple;
$loglevel-error: red;
$loglevel-warning: yellow;
$loglevel-info: green;
$loglevel-debug: blue;
$loglevel-trace: lightblue;
$loglevel-unknown: gray;